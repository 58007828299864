var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("event-storming-canvas", {
    ref: "designer",
    staticStyle: { height: "100%", width: "100%" },
    attrs: {
      elementTypes: _vm.elementTypes,
      elementListBeanPath: "classDefinitions",
      relationListBeanPath: "relations",
      relationVueComponentName: "class-relation",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }